import { ref, computed, watch, } from '@vue/composition-api'
import { useSalaries } from './useSalaries'
import { capitalize, currency } from '@/utils/filter'

import useAPI from '@/utils/useAPI'
import i18n from '@/libs/i18n'
// import router from '@/router'

import SearchFilter from '../../../components/searchFilterV2/SearchFilter'
import ModalUploadSalary from '@/components/prompt/Upload'
import ModalSalaries from '@/components/prompt/Salaries'
import CardSalary from '@/components/card/Salary'
import { getRoute } from '../../../utils/utils'

export const mixinSalaries = {
  components: {
    SearchFilter,
    ModalUploadSalary,
    ModalSalaries,
    CardSalary
  },
  props: {},
  setup () {
    const {
      submitValidatedSalary,
      recognizeSalary,
      downloadSalary,
      removeSalary,
    } = useSalaries()

    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const newSalaryFile = ref(null)
    const salaryByUploadModalShow = ref(false)

    const currentSalaries = ref([])
    const salaryModalTitle = ref(i18n.t('NewSalary'))
    const salariesModalShow = ref(false)

    // const newSalary = ref({})
    // const newSalaryModalShow = ref(false)

    const sortOptions = ref([    ])
    const columns = ref([
      {
        display: capitalize(i18n.t('month')),
        key: 'month',
        isDate: true,
        sortable: true
      },
      {
        display: capitalize(i18n.tc('employee')),
        key: '_employeeSalary',
        sortable: true
      },
      {
        display: capitalize(i18n.t('paidByEmployer')),
        key: 'paidByEmployer',
        sortable: true
      },
      {
        display: capitalize(i18n.t('net')),
        key: 'net',
        sortable: true
      },
      {
        display: capitalize(i18n.t('paidVacationRemaining')),
        key: 'paidVacationRemaining',
        sortable: true
      },
      {
        display: capitalize(i18n.t('luncheonVoucherQuantity')),
        key: 'luncheonVoucherQuantity',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { salaries } = useAPI()
    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newSalaryFile, (val) => {
      if (val != null) {
        recognizeSalary(val)
          .then(response => {
            // console.log(response.data)
            salaryByUploadModalShow.value = false
            currentSalaries.value = response.data
            newSalaryFile.value = null
            salariesModalShow.value = true

            let i = 0
            currentSalaries.value.forEach(s => {
              s._uuid = i
              i++
            })

          })
      }

    })

    watch(salariesModalShow, val => {
      if (val == false) {
        currentSalaries.value = []
        salaryModalTitle.value = i18n.t('NewSalary')
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchUsers,
      fetchSalaries,
    } = useAPI()

    const selectSalary = (salary) => {
      salaryModalTitle.value = i18n.t('EditSalary')

      fetchSalaries(salary.id).then((salary) => {
        currentSalaries.value = [salary]
        salariesModalShow.value = true
      })
    }

    const submitValidatedSalariesLocal = (salaries) => {
      // console.log(JSON.parse(JSON.stringify(salaries)))
      salaries.forEach(salary => {
        submitValidatedSalaryLocal(salary)
      })
    }

    const submitValidatedSalaryLocal = (salary) => {
      submitValidatedSalary(salary)
        .then(response => {
          salariesModalShow.value = false
          resetSalary()
        })
    }

    const removeSalaryLocal = (salary) => {
      removeSalary(salary)
        .then(response => {
          // router.push({ name: 'Salaries' })
        })
    }

    const resetSalary = () => {
      currentSalaries.value = JSON.parse(JSON.stringify([]))
    }

    const getLoadedSalaries = (salaries) => {
      let output = 0
      salaries.forEach(s => output += parseFloat(s.paidByEmployer))

      return output
    }

    const getNetAmounts = (salaries) => {
      let output = 0
      salaries.forEach(s => output += parseFloat(s.net))

      return output
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().params != null && ('salaryId' in getRoute().params)) {
      fetchSalaries(getRoute().params.salaryId).then(() => {
        // fetchForDetails()
        fetchUsers()
      })
    } else {
      fetchSalaries().then(() => {
        // fetchForDetails()
        fetchUsers()
      })
    }
    // fetchSalaries()

    return {
      // Components
      capitalize,
      currency,

      // Data
      newSalaryFile,
      salaryByUploadModalShow,
      currentSalaries,
      salaryModalTitle,
      salariesModalShow,
      // newSalary,
      // newSalaryModalShow,
      sortOptions,
      columns,

      // Computed
      salaries,

      // Methods
      // addSalaryByUpload,
      // addSalary,
      selectSalary,
      submitValidatedSalariesLocal,
      submitValidatedSalaryLocal,
      downloadSalary,
      removeSalaryLocal,
      getLoadedSalaries,
      getNetAmounts,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteSalaryAlert (salary) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theSalary') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeSalaryLocal(salary)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}